const addInfo = {
  title: 'UserSettings',
  icon: 'user-settings'
}
const userSettingsRouter = {
  path: '/user-settings',
  hidden: true,
  meta: {
    title: 'UserSettings',
  },
  children: [
    {
      path: 'user-profile',
      component: () => import('@/views/UserSettings/UserProfile/user-profile'),
      name: 'UserProfile',
      meta: {
        title: 'UserProfile',
        parent: addInfo
      }
    },
    {
      path: 'device-settings',
      component: () => import('@/views/UserSettings/DeviceSettings/device-settings'),
      name: 'DeviceSettings',
      meta: {
        title: 'DeviceSettings',
        parent: addInfo
      }
    },
    {
      path: 'alarm-history',
      component: () => import('@/views/UserSettings/DeviceSettings/alarm-history'),
      name: 'AlarmHistory',
      meta: {
        title: 'AlarmHistory',
        parent: addInfo
      }
    },
    {
      path: 'alarm/:id',
      component: () => import('@/views/UserSettings/DeviceSettings/alarm-details'),
      name: 'AlarmDetails',
      meta: {
        title: 'AlarmDetails',
        parent: addInfo
      }
    },
    {
      path: 'firmware-management',
      component: () => import('@/views/UserSettings/DeviceSettings/firmware-management'),
      name: 'FirmwareManagement',
      meta: {
        title: 'FirmwareManagement',
        parent: addInfo
      }
    },
    {
      path: 'help-center',
      component: () => import('@/views/UserSettings/HelpCenter/help-center'),
      name: 'HelpCenter',
      meta: {
        title: 'HelpCenter',
        parent: addInfo
      }
    },
  ]
}

export default userSettingsRouter