/*! Element Plus v2.7.6 */

(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
  // eslint-disable-next-line no-undef
  typeof define === 'function' && define.amd ? define(factory) :
    // eslint-disable-next-line no-undef
  (global = typeof globalThis !== 'undefined' ? globalThis : global || self, global.ElementPlusLocaleEn = factory());
})(this, (function () { 'use strict';

var bm = {
  name: "bm",
  el: {
    breadcrumb: {
      label: "Breadcrumb"
    },
    colorpicker: {
      confirm: "OK",
      clear: "Bersihkan",
      defaultLabel: "pemilih warna",
      description: "warna semasa adalah {color}. tekan enter untuk memilih warna baru."
    },
    datepicker: {
      now: "Sekarang",
      today: "Hari Ini",
      cancel: "Batal",
      clear: "Bersihkan",
      confirm: "OK",
      dateTablePrompt: "Gunakan kekunci anak panah dan enter untuk memilih hari dalam bulan",
      monthTablePrompt: "Gunakan kekunci anak panah dan enter untuk memilih bulan",
      yearTablePrompt: "Gunakan kekunci anak panah dan enter untuk memilih tahun",
      selectedDate: "Tarikh yang dipilih",
      selectDate: "Pilih tarikh",
      selectTime: "Pilih masa",
      startDate: "Tarikh Mula",
      startTime: "Masa Mula",
      endDate: "Tarikh Tamat",
      endTime: "Masa Tamat",
      prevYear: "Tahun Sebelumnya",
      nextYear: "Tahun Depan",
      prevMonth: "Bulan Sebelumnya",
      nextMonth: "Bulan Depan",
      year: "",
      month1: "Januari",
      month2: "Februari",
      month3: "Mac",
      month4: "April",
      month5: "Mei",
      month6: "Jun",
      month7: "Julai",
      month8: "Ogos",
      month9: "September",
      month10: "Oktober",
      month11: "November",
      month12: "Disember",
      week: "minggu",
      weeks: {
        sun: "Aha",
        mon: "Isn",
        tue: "Sel",
        wed: "Rab",
        thu: "Kha",
        fri: "Jum",
        sat: "Sab"
      },
      weeksFull: {
        sun: "Ahad",
        mon: "Isnin",
        tue: "Selasa",
        wed: "Rabu",
        thu: "Khamis",
        fri: "Jumaat",
        sat: "Sabtu"
      },
      months: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mac",
        apr: "Apr",
        may: "Mei",
        jun: "Jun",
        jul: "Jul",
        aug: "Ogos",
        sep: "Sep",
        oct: "Okt",
        nov: "Nov",
        dec: "Dis"
      }
    },
    inputNumber: {
      decrease: "kurangkan nombor",
      increase: "tambah nombor"
    },
    select: {
      loading: "Memuatkan",
      noMatch: "Tiada data sepadan",
      noData: "Tiada data",
      placeholder: "Pilih"
    },
    dropdown: {
      toggleDropdown: "Togol Dropdown"
    },
    cascader: {
      noMatch: "Tiada data sepadan",
      loading: "Memuatkan",
      placeholder: "Pilih",
      noData: "Tiada data"
    },
    pagination: {
      goto: "Pergi ke",
      pagesize: "/halaman",
      total: "Jumlah {total}",
      pageClassifier: "",
      page: "Halaman",
      prev: "Pergi ke halaman sebelumnya",
      next: "Pergi ke halaman seterusnya",
      currentPage: "halaman {pager}",
      prevPages: "Halaman {pager} sebelumnya",
      nextPages: "Halaman {pager} seterusnya",
      deprecationWarning: "Penggunaan yang sudah tidak disokong dikesan, sila rujuk dokumentasi el-pagination untuk maklumat lebih lanjut"
    },
    dialog: {
      close: "Tutup dialog ini"
    },
    drawer: {
      close: "Tutup dialog ini"
    },
    messagebox: {
      title: "Mesej",
      confirm: "OK",
      cancel: "Batal",
      error: "Input tidak sah",
      close: "Tutup dialog ini"
    },
    upload: {
      deleteTip: "tekan delete untuk mengalih keluar",
      delete: "Padam",
      preview: "Pratonton",
      continue: "Teruskan"
    },
    slider: {
      defaultLabel: "gelongsor antara {min} dan {max}",
      defaultRangeStartLabel: "pilih nilai mula",
      defaultRangeEndLabel: "pilih nilai akhir"
    },
    table: {
      emptyText: "Tiada Data",
      confirmFilter: "Sahkan",
      resetFilter: "Tetapkan Semula",
      clearFilter: "Semua",
      sumText: "Jumlah"
    },
    tour: {
      next: "Seterusnya",
      previous: "Sebelumnya",
      finish: "Selesai"
    },
    tree: {
      emptyText: "Tiada Data"
    },
    transfer: {
      noMatch: "Tiada data sepadan",
      noData: "Tiada data",
      titles: ["Senarai 1", "Senarai 2"],
      filterPlaceholder: "Masukkan kata kunci",
      noCheckedFormat: "{total} item",
      hasCheckedFormat: "{checked}/{total} dipilih"
    },
    image: {
      error: "GAGAL"
    },
    pageHeader: {
      title: "Kembali"
    },
    popconfirm: {
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak"
    },
    carousel: {
      leftArrow: "Anak panah kiri carousel",
      rightArrow: "Anak panah kanan carousel",
      indicator: "Pertukaran carousel ke indeks {index}"
    }
  }
};

  return bm;

}));
