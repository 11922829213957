import { createRouter, createWebHistory } from 'vue-router'

import fuelManagementRouter from '@/router/modules/fuel-management'
import vehicleRouter from '@/router/modules/vehicle'
import fleetAccessSettingsRouter from '@/router/modules/fleet-access-settings'
import GPSTrackingRouter from '@/router/modules/gps-tracking'
import driverRouter from '@/router/modules/driver'
import userSettingsRouter from '@/router/modules/user-settings'
import notificationRouter from '@/router/modules/notification'
import reportRouter from '@/router/modules/report'
import eventCalendarRouter from '@/router/modules/event-calendar'
import checklistRouter from '@/router/modules/checklist'
import breakdownSupportRouter from '@/router/modules/breakdown-support'
import smartAnalyticsRouter from '@/router/modules/smart-analytics'

export const constantRoutes = [
  {
    path: '/test',
    component: () => import('@/views/test.vue'),
    name: 'test',
    hidden: true
  },
  {
    path: '/redirect',
    component: () => import('@/views/redirect/redirect'),
    name: 'Redirect',
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/Login/login'),
    name: 'LOGIN',
    meta: {
      bypass: true
    },
    hidden: true
  },
  userSettingsRouter,
  notificationRouter,
  {
    path: '/',
    component: () => import('@/views/KPIDashboard/kpi-dashboard'),
    name: 'KPIDashboard',
    meta: { title: 'KPIDashboard', icon: 'dashboard', parent: { title: 'KPIDashboard', icon: 'dashboard'} }
  },
  smartAnalyticsRouter,
  eventCalendarRouter,
  GPSTrackingRouter,
  vehicleRouter,
  driverRouter,
  checklistRouter,
  reportRouter,
  fuelManagementRouter,
  breakdownSupportRouter,
  fleetAccessSettingsRouter,
  {
    path: '/vehicle-certificate',
    name: 'VehicleCertificate',
    component: () => import('@/certificate/vehicle-certificate.vue'),
    hidden: true
  },
  {
    path: '/401',
    name: 'error-401',
    component: () => import('@/views/ErrorPage/401-Error.vue'),
    hidden: true
  },
  {
    path: '/404',
    name: 'error-404',
    component: () => import('@/views/ErrorPage/404-Error.vue'),
    hidden: true
  }
]

export const asyncRoutes = [

]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BUILD_PATH),
  // history: createWebHistory(),
  routes: constantRoutes
})

export default router
