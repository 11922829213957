const addInfo = {
  title: 'FleetAccessSettings',
  icon: 'fleet-access-settings'
}
const fleetAccessSettingsRouter = {
  path: '/fleet-access-settings',
  meta: {
    title: 'FleetAccessSettings',
    icon: 'fleet-access-settings'
  },
  children: [
    {
      path: 'manage-user',
      component: () => import('@/views/fleet-access-settings/ManageUser/manage-user'),
      name: 'ManageUser',
      meta: { title: 'ManageUser', icon: 'submenu', parent: addInfo }
    },
    {
      path: 'user-form/:id?',
      component: () => import('@/views/fleet-access-settings/ManageUser/user-form'),
      hidden: true,
      name: 'UserForm',
      meta: { title: 'UserForm', icon: 'submenu', parent: addInfo, activeMenu: '/fleet-access-settings/manage-user' }
    },
    {
      path: 'fleet-group-management',
      component: () => import('@/views/fleet-access-settings/FleetGroupManagement/fleet-group-management'),
      name: 'FleetGroupManagement',
      meta: { title: 'FleetGroupManagement', icon: 'submenu', parent: addInfo }
    },
    {
      path: 'fleet-group-form/:id?',
      component: () => import('@/views/fleet-access-settings/FleetGroupManagement/fleet-group-form'),
      hidden: true,
      name: 'FleetGroupForm',
      meta: { title: 'FleetGroupForm', icon: 'submenu', parent: addInfo, activeMenu: '/fleet-access-settings/fleet-group-management', enableBack: true, backPage: 'FleetGroupManagement' }
    },
    {
      path: 'company-management',
      component: () => import('@/views/fleet-access-settings/CompanyManagement/company-management'),
      name: 'CompanyManagement',
      meta: { title: 'CompanyManagement', icon: 'submenu', parent: addInfo }
    },
    {
      path: 'company-form/:id?',
      component: () => import('@/views/fleet-access-settings/CompanyManagement/company-form'),
      hidden: true,
      name: 'CompanyForm',
      meta: { title: 'CompanyForm', icon: 'submenu', parent: addInfo, activeMenu: '/fleet-access-settings/company-management' }
    },
    {
      path: 'email-notification-settings',
      component: () => import('@/views/fleet-access-settings/EmailNotificationSettings/email-notification-settings'),
      name: 'EmailNotificationSettings',
      meta: { title: 'EmailNotificationSettings', icon: 'submenu', parent: addInfo }
    },
    {
      path: 'email-notification-settings-form/:id?',
      component: () => import('@/views/fleet-access-settings/EmailNotificationSettings/email-notification-form'),
      hidden: true,
      name: 'EmailNotificationSettingsForm',
      meta: { title: 'EmailNotificationSettingsForm', icon: 'submenu', parent: addInfo, activeMenu: '/fleet-access-settings/email-notification-settings' }
    },
    {
      path: 'roles-management',
      component: () => import('@/views/fleet-access-settings/RolesManagement/roles-management'),
      name: 'RolesManagement',
      meta: { title: 'RolesManagement', icon: 'submenu', parent: addInfo }
    },
    {
      path: 'roles-form/:id?',
      component: () => import('@/views/fleet-access-settings/RolesManagement/roles-form'),
      hidden: true,
      name: 'RolesForm',
      meta: { title: 'RolesForm', icon: 'submenu', parent: addInfo, activeMenu: '/fleet-access-settings/roles-management' }
    },
    {
      path: 'audit-logs',
      component: () => import('@/views/fleet-access-settings/AuditLogs/audit-logs'),
      name: 'AuditLogs',
      meta: { title: 'AuditLogs', icon: 'submenu', parent: addInfo }
    }
  ]
}

export default fleetAccessSettingsRouter
