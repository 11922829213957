const addInfo = {
  title: 'Checklist',
  icon: 'checklist'
}
const checklistRouter = {
  path: '/checklist',
  meta: {
    title: 'Checklist',
    icon: 'checklist'
  },
  children: [
    {
      path: 'manage-checklist',
      component: () => import('@/views/checklist/ManageChecklist/manage-checklist'),
      name: 'ManageChecklist',
      meta: { title: 'ManageChecklist', icon: 'submenu', parent: addInfo }
    },
    {
      path: 'manage-checklist-form/:id?',
      component: () => import('@/views/checklist/ManageChecklist/manage-checklist-form'),
      name: 'ManageChecklistForm',
      hidden: true,
      meta: { title: 'ManageChecklistForm', icon: 'submenu', parent: addInfo, activeMenu: '/checklist/manage-checklist', enableBack: true, backPage: 'ManageChecklist' }
    },
    {
      path: 'company-checklist',
      component: () => import('@/views/checklist/CompanyChecklist/company-checklist'),
      name: 'CompanyChecklist',
      meta: { title: 'CompanyChecklist', icon: 'submenu', parent: addInfo }
    }
  ]
}

export default checklistRouter