export default {
  title: {
    KPIDashboard: 'Papan Pemuka KPI',
    GpsTracking: 'Penjejakan GPS',
    EventCalendar: 'Kalendar Acara',
    EventList: 'Senarai Acara',
    Calendar: 'Kalender',
    FullMapTracking: 'Penjejakan Peta Penuh',
    MultiVehicleTracking: 'Penjejakan Pelbagai Kenderaan',
    VehicleTrackingList: 'Senarai Penjejakan Kenderaan',
    HistoricalPlayback: 'Main Semula Sejarah',
    HistoricalPlaybackDetails: 'Butiran Main Semula Sejarah',
    PtoLog: 'Log PTO',
    PoiManagement: 'Pengurusan POI',
    GeofenceManagement: 'Pengurusan Geofence',
    Vehicle: 'Kenderaan',
    ManageVehicle: 'Urus Kenderaan',
    MaintenanceLog: 'Log Penyelenggaraan',
    Overview: 'Tinjauan',
    ServiceLog: 'Log Perkhidmatan',
    VehicleLog: 'Log Kenderaan',
    MaintenanceForm: 'Borang Penyelenggaraan',
    AddMaintenanceForm: 'Tambah Borang Penyelenggaraan',
    EditMaintenanceForm: 'Edit Borang Penyelenggaraan',
    Tools: 'Alat',
    MaintenanceCategory: 'Kategori Penyelenggaraan',
    Driver: 'Pemandu',
    ManageDriver: 'Urus Pemandu',
    ManageDriverForm: 'Borang Pemandu',
    AddDriverForm: 'Tambah Borang Pemandu',
    EditDriverForm: 'Edit Borang Pemandu',
    DriverDetails: 'Butiran Pemandu',
    DriverInquiry: 'Siasatan Pemandu',
    DriverApp: 'Aplikasi Pemandu',
    CheckInOut: 'Daftar Masuk / Keluar',
    ChecklistApproval: 'Kelulusan Senarai Semak',
    DriverClaimReport: 'Laporan Tuntutan Pemandu',
    DriverTracing: 'Penjejakan Pemandu',
    Trailer: 'Treler',
    ManageTrailer: 'Urus Treler',
    TrailerTrackingPage: 'Halaman Penjejakan Treler',
    FuelManagement: 'Pengurusan Bahan Api',
    FuelLog: 'Log Bahan Api',
    FuelConsumptionHistory: 'Sejarah Penggunaan Bahan Api',
    RefuelManagement: 'Pengurusan Isi Semula',
    FuelPriceManagement: 'Pengurusan Harga Bahan Api',
    FuelSpend: 'Perbelanjaan Bahan Api',
    FleetAccessSettings: 'Tetapan Akses Armada',
    FleetGroupForm: 'Borang Kumpulan Armada',
    AddFleetGroupForm: 'Tambah Borang Kumpulan Armada',
    EditFleetGroupForm: 'Edit Borang Kumpulan Armada',
    ManageUser: 'Urus Pengguna',
    UserForm: 'Borang Pengguna',
    AddUserForm: 'Tambah Borang Pengguna',
    EditUserForm: 'Edit Borang Pengguna',
    FleetGroupManagement: 'Pengurusan Kumpulan Armada',
    CompanyManagement: 'Pengurusan Syarikat',
    CompanyForm: 'Borang Syarikat',
    AddCompanyForm: 'Tambah Borang Syarikat',
    EditCompanyForm: 'Edit Borang Syarikat',
    EmailNotificationSettings: 'Tetapan Notifikasi E-mel',
    EmailNotificationSettingsForm: 'Borang Tetapan Notifikasi E-mel',
    AddEmailNotificationSettingsForm: 'Tambah Borang Tetapan Notifikasi E-mel',
    EditEmailNotificationSettingsForm: 'Edit Borang Tetapan Notifikasi E-mel',
    RolesManagement: 'Pengurusan Peranan',
    RolesForm: 'Borang Peranan',
    AddRolesForm: 'Tambah Borang Peranan',
    EditRolesForm: 'Edit Borang Peranan',
    AuditLogs: 'Log Audit',
    Report: 'Laporan',
    VehicleIndustry: 'Industri Kenderaan',
    VehicleForm: 'Borang Kenderaan',
    AddVehicleForm: 'Tambah Borang Kenderaan',
    EditVehicleForm: 'Edit Borang Kenderaan',
    ManageTracker: 'Urus Penjejak',
    TrackerForm: 'Borang Penjejak',
    AddTrackerForm: 'Tambah Borang Penjejak',
    EditTrackerForm: 'Edit Borang Penjejak',
    MapInstance: 'Instans Peta',
    MapInstanceDetails: 'Butiran Instans Peta',
    UserSettings: 'Tetapan Pengguna',
    UserProfile: 'Profil Pengguna',
    HelpCenter: 'Pusat Bantuan',
    DeviceSettings: 'Tetapan Peranti',
    FirmwareManagement: 'Pengurusan Firmware',
    AlarmHistory: 'Sejarah Penggera',
    AlarmDetails: 'Butiran Penggera',
    Notification: 'Notifikasi',
    Checklist: 'Senarai Semak',
    ManageChecklist: 'Urus Senarai Semak',
    ManageChecklistForm: 'Borang Senarai Semak',
    AddChecklistForm: 'Tambah Borang Senarai Semak',
    EditChecklistForm: 'Edit Borang Senarai Semak',
    CompanyChecklist: 'Senarai Semak Syarikat',
    BreakdownSupport: 'Penyelesaian Masalah',
    ManageWorkshop: 'Urus Workshop',
    ManageWorkshopForm: 'Borang Workshop',
    AddWorkshopForm: 'Tambah Borang Workshop',
    EditWorkshopForm: 'Edit Borang Workshop',
    ManageWorkshopRating: 'Urus Penilaian Workshop',
    SmartAnalytics: 'Analisis Pintar',
    OperationalPerformance: 'Performa Operasional',
    VehiclePerformance: 'Performa Kenderaan',
    DriverPerformance: 'Performa Pemandu',
},
genderType: {
    MALE: 'Lelaki',
    FEMALE: 'Perempuan',
    3: 'Tidak Mahu Nyatakan'
},
employmentStatus: {
    1: 'Aktif',
},
driverType: {
    DIRECT: 'Pemandu Langsung',
    FEEDER: 'Pemandu Feeder'
},
approvalStatus: {
    1: 'Diluluskan',
    2: 'Ditolak',
    3: 'Tertangguh'
},
serviceType: {
    1: 'Biasa',
    2: 'Segera',
    3: 'Waranti',
    4: 'Servis',
    5: 'Penyelesaian Masalah',
    6: 'Puspakom',
    7: 'Kemalangan',
    8: 'Hilang Sepenuhnya',
    9: 'Overhaul Enjin'
},
rateStatus: {
    1: 'Cemerlang',
    2: 'Baik',
    3: 'Boleh Diterima'
},
portable: {
    0: 'Umum',
    1: 'Peranti Mudah Alih',
    2: 'Terikat Treler'
},
vehicleStatus: {
    null: 'Beroperasi',
    parking: 'Parkir',
    accident: 'Kemalangan',
    deviceNotOnline: 'Peranti Tidak Dalam Talian',
    repair: 'Baik Pulih',
    immobilized: 'Tidak Bergerak'
},
integrationType: {
    vehicle: 'Kenderaan',
    user: 'Pengguna',
    group: 'Kumpulan Armada'
},
faq: {
    dashboard_question_1: "Apakah itu Papan Pemuka KPI?",
    dashboard_answer_1: "Papan Pemuka KPI membolehkan pengguna mendapatkan maklumat ringkas tentang kenderaan dan pemandu. Maklumat yang tersedia di halaman ini termasuk Bil. Kenderaan Aktif Hari Ini, Amaran Penyelenggaraan, Aktiviti Mencurigakan, Bil. Amaran, Carta Penggunaan Harian Kenderaan, Penggunaan Minyak & Jarak Perjalanan, Senarai 5 Teratas Masa Melahu, Penggunaan Bahan Api, dan Kelajuan Tinggi.",
    dashboard_question_2: "Apakah maklumat yang dipaparkan dalam 'Bil. Kenderaan Aktif Hari Ini'?",
    dashboard_answer_2: "'Bil. Kenderaan Aktif Hari Ini' menunjukkan jumlah kenderaan aktif hari ini daripada jumlah keseluruhan kenderaan yang didaftarkan dalam sistem. Dalam paparan terperinci, akan ada senarai kenderaan aktif termasuk nombor plat dan jarak perjalanan kenderaan.",
    dashboard_question_3: "Apakah maklumat yang dipaparkan dalam 'Aktiviti Mencurigakan'?",
    dashboard_answer_3: "'Aktiviti Mencurigakan' memaparkan butiran aktiviti mencurigakan yang dikesan pada kenderaan. Aktiviti mencurigakan ini boleh diberikan kepada pengguna atau kakitangan lain melalui alamat e-mel.",
    dashboard_question_4: "Apakah maklumat yang dipaparkan dalam 'Amaran Penyelenggaraan'?",
    dashboard_answer_4: "'Amaran Penyelenggaraan' memaparkan butiran penyelenggaraan kenderaan yang akan datang. Amaran ini boleh diberikan kepada pengguna atau kakitangan lain melalui alamat e-mel.",
    dashboard_question_5: "Bagaimana Untuk Menukar Jangka Masa Laporan Papan Pemuka KPI?",
    dashboard_answer_5: "Untuk menukar tempoh masa laporan Papan Pemuka KPI, klik pada senarai pilihan 'Semalam' dan pilih jangka masa dari 'Semalam', '7 Hari Lalu', 'Minggu Ini', '30 Hari Lalu', dan 'Bulan Ini', kemudian tekan butang 'Cari' di sebelah kanan.",
    event_calendar_question_1: "Apakah itu Kalendar Acara?",
    event_calendar_answer_1: "Kalendar Acara merangkum tarikh luput seperti insurans, permit, cukai jalan, dan sebagainya dalam bentuk kalendar. Ia menyediakan pandangan yang lebih ringkas untuk membantu pengguna mengesan acara yang akan datang dengan lebih mudah.",
    event_calendar_question_2: "Di mana Saya Boleh Mendapatkan Butiran Acara?",
    event_calendar_answer_2: "Untuk mendapatkan butiran acara, klik pada acara tersebut dan ia akan memaparkan senarai kenderaan yang terlibat dalam acara itu. Klik pada nombor kenderaan akan membawa anda ke butiran kenderaan tersebut.",
    gps_tracking_question_1: "Di Mana Lokasi Semasa Kenderaan?",
    gps_tracking_answer_1: "Pergi ke 'Penjejakan GPS' > 'Senarai Penjejakan Kenderaan'. Pilih dan klik nombor pendaftaran kenderaan untuk menjejak kenderaan dari senarai.",
    gps_tracking_question_2: "Bagaimana Untuk Menjejak Pelbagai Kenderaan Dalam Satu Halaman?",
    gps_tracking_answer_2: "Pergi ke 'Penjejakan GPS' > 'Penjejakan Pelbagai Kenderaan'. Pilih nombor pendaftaran kenderaan untuk menjejak kenderaan dari senarai pilihan. Pengguna boleh menjejak sehingga 4 kenderaan dalam satu halaman.",
    gps_tracking_question_3: "Bagaimana Untuk Menapis Kenderaan Mengikut Kumpulan?",
    gps_tracking_answer_3: "Untuk menapis kenderaan mengikut kumpulan, tekan senarai pilihan '-Sila pilih kumpulan-'. Pilih kumpulan dan tekan 'Cari' di sebelah kanan untuk memproses penapisan.",
    gps_tracking_question_4: "Bagaimana Untuk Mencari Lokasi Koordinat atau Alamat?",
    gps_tracking_answer_4: "Untuk mencari lokasi koordinat atau alamat, klik pada alamat atau koordinat tersebut. Lokasi akan dipaparkan dalam Peta Google.",
    gps_tracking_question_5: "Di Mana Saya Boleh Menjejak Semua Kenderaan Yang Didaftarkan?",
    gps_tracking_answer_5: "Semua kenderaan yang tersedia boleh dijejak dengan pergi ke 'Penjejakan GPS' > 'Peta Penjejakan Penuh Kenderaan'.",
    gps_tracking_question_6: "Bagaimana Untuk Melihat Perjalanan Penuh Kenderaan Dalam Sehari?",
    gps_tracking_answer_6: "Pergi ke 'Penjejakan GPS' > 'Main Semula Sejarah' dan pilih nombor pendaftaran kenderaan untuk dijejak. Seterusnya, pilih tarikh, masa, dan kelajuan main semula yang diingini sebelum klik butang 'Cari'. Selepas main semula dimulakan, klik 'Jalur Penuh' pada panel kanan untuk memaparkan jalur penuh.",
    vehicle_question_1: "Di Mana dan Bagaimana Menambah Kenderaan Baru?",
    vehicle_answer_1: "Pergi ke 'Kenderaan' > 'Tambah Kenderaan Baru', masukkan maklumat yang diperlukan dalam borang dan tekan 'Hantar'.",
    vehicle_question_2: "Di Mana dan Bagaimana Untuk Mengurus Kenderaan?",
    vehicle_answer_2: "Pergi ke 'Kenderaan' > 'Urus Kenderaan', pilih kenderaan untuk diedit. Masukkan maklumat yang dikemas kini ke dalam borang dan tekan 'Simpan'.",
    vehicle_question_3: "Di Mana Saya Boleh Mendapatkan Log Penyelenggaraan Kenderaan?",
    vehicle_answer_3: "Pergi ke 'Kenderaan' > 'Log Penyelenggaraan Kenderaan', semua maklumat kenderaan akan disenaraikan dalam jadual.",
    vehicle_question_4: "Di Mana Saya Boleh Muat Turun/Cetak Log Penyelenggaraan Kenderaan?",
    vehicle_answer_4: "Pergi ke 'Kenderaan' > 'Log Penyelenggaraan Kenderaan', di sudut kanan atas akan terdapat format 'CSV', 'Excel', 'PDF' yang tersedia untuk muat turun dan 'Cetak' untuk mencetak Log Penyelenggaraan Kenderaan.",
    driver_question_1: "Di Mana dan Bagaimana Menambah Pemandu Baru?",
    driver_answer_1: "Pergi ke 'Pemandu' > 'Tambah Pemandu Baru', masukkan maklumat yang diperlukan dalam borang dan tekan 'Hantar'.",
    driver_question_2: "Di Mana dan Bagaimana Untuk Mengurus Pemandu?",
    driver_answer_2: "Pergi ke 'Pemandu' > 'Urus Pemandu', pilih pemandu untuk diedit dan tekan 'Edit Profil' di sudut kanan atas. Masukkan maklumat yang dikemas kini ke dalam borang dan tekan 'Simpan'.",
    driver_question_3: "Di Mana Saya Boleh Mendapatkan Log Pemandu?",
    driver_answer_3: "Pergi ke 'Pemandu' > 'Log Pemandu', maklumat akan disenaraikan dalam jadual.",
    fleet_access_setting_question_1: "Di Mana Untuk Menambah dan Mengurus Pengguna serta Kumpulan Armada?",
    fleet_access_setting_answer_1: "Pergi ke 'Tetapan Akses Armada' dan pergi ke pengurusan kumpulan pengguna tertentu. Kemudian pilih untuk Menambah atau Mengurus kumpulan pengguna tersebut. Ikuti arahan dan isi butiran yang diperlukan.",
    fuel_management_question_1: "Di Mana Saya Boleh Menyemak Laporan Penggunaan Bahan Api Kenderaan?",
    fuel_management_answer_1: "Pergi ke 'Pengurusan Bahan Api' > 'Laporan Penggunaan Bahan Api', pilih kenderaan dan tekan 'Cari'. Semua maklumat penggunaan bahan api kenderaan akan dipaparkan dalam jadual di bawah.",
    fuel_management_question_2: "Di Mana Saya Boleh Menyemak Laporan Isi Semula Bahan Api Kenderaan?",
    fuel_management_answer_2: "Pergi ke 'Pengurusan Bahan Api' > 'Laporan Isi Semula', jadual di bawah akan menyenaraikan butiran isi semula kenderaan, disusun mengikut yang terbaru secara lalai.",
    fuel_management_question_3: "Bagaimana Untuk Memperincikan Laporan Isi Semula Untuk Kenderaan Tertentu?",
    fuel_management_answer_3: "Pergi ke 'Pengurusan Bahan Api' > 'Laporan Isi Semula', pilih nombor pendaftaran kenderaan dan jangka masa tertentu kemudian tekan butang 'Cari'. Jadual di bawah akan menunjukkan semua maklumat isi semula kenderaan dalam tempoh masa tertentu.",
    report_question_1: "Bagaimana Untuk Memuat Turun Laporan?",
    report_answer_1: "Laporan boleh dimuat turun dengan menekan 'Eksport' atau 'Eksport Laporan Penuh' di sudut kanan atas paparan. Secara lalai, laporan adalah dalam format excel.",
    report_question_2: "Bagaimana Saya Boleh Memuat Turun Laporan Dalam Format Lain?",
    report_answer_2: "Untuk memuat turun laporan dalam format lain, tekan 'CSV', 'Excel', atau 'PDF' yang dipaparkan di sudut kanan atas butiran laporan.",
    report_question_3: "Bagaimana Saya Boleh Mencetak Laporan?",
    report_answer_3: "Untuk mencetak laporan, tekan 'Cetak' yang dipaparkan di sudut kanan atas butiran laporan.",
},
geofenceModule: {
  checkpoint: 'Pemeriksaan',
  checkpointwithoutnotification: 'Pemeriksaan Tanpa Notifikasi',
  prohibited: 'Dilarang',
  inout: 'Masuk/Keluar',
  in: 'Masuk',
  out: 'Keluar',
  color: {
      null: 'Default',
      black: 'Hitam',
      gray: 'Kelabu',
      red: 'Merah',
      crimson: 'Merah Tua',
      blue: 'Biru',
      cyan: 'Sian',
      olive: 'Zaitun',
      green: 'Hijau',
      lime: 'Limau Nipis',
      yellow: 'Kuning',
      orange: 'Oren',
      salmon: 'Salmon',
      purple: 'Ungu',
  }
},
please_enter_username: 'Sila Masukkan Nama Pengguna',
please_enter_password: 'Sila Masukkan Kata Laluan',
login: 'Log Masuk',
logout: 'Log Keluar',
switchLang: 'Tukar Bahasa',
permit_sah: 'Permit Sah',
minyak_engine: 'Minyak Enjin',
Jan: 'Januari',
Feb: 'Februari',
Mar: 'Mac',
Apr: 'April',
May: 'Mei',
Jun: 'Jun',
Jul: 'Julai',
Aug: 'Ogos',
Sep: 'September',
Oct: 'Oktober',
Nov: 'November',
Dec: 'Disember',
no_data: 'Tiada Data',
start_date: 'Tarikh Mula',
start_time: 'Masa Mula',
end_date: 'Tarikh Tamat',
end_time: 'Masa Tamat',
playback_the_track_when_speed: 'Mainkan semula trek apabila kelajuan >=',
filter: 'Tapis',
add: 'Tambah',
edit: 'Edit',
edit_profile: 'Edit Profil',
back: 'Kembali',
cancel: 'Batal',
cancel_action: 'Batalkan Tindakan',
delete: 'Padam',
are_you_sure_delete: 'Adakah anda pasti untuk memadam <b>%{name}</b>',
are_you_sure_resend_command_for: 'Adakah anda pasti untuk menghantar semula arahan untuk <b>%{name}</b>',
are_you_sure_resend_all_command: 'Adakah anda pasti untuk menghantar semula semua arahan',
are_you_sure_cancel_all_command: 'Adakah anda pasti untuk membatalkan semua arahan',
save: 'Simpan',
search: 'Cari',
apply: 'Terapkan',
clear_all: 'Padam Semua',
select_all: 'Pilih Semua',
deselect_all: 'Nyahpilih Semua',
draft: 'Draf',
completed: 'Selesai',
reset: 'Tetap Semula',
export: 'Eksport',
column: 'Lajur',
action: 'Tindakan',
total: 'Jumlah',
online: 'Dalam Talian',
offline: 'Luar Talian',
active: 'Aktif',
inactive: 'Tidak Aktif',
select_a_group: 'Pilih Kumpulan',
select_a_vehicle: 'Pilih Kenderaan',
select_a_vehicle_type: 'Pilih Jenis Kenderaan',
select_a_engine_type: 'Pilih Jenis Enjin',
select_a_plate_no: 'Pilih No Plat',
select_driver: 'Pilih Pemandu',
name: 'Nama',
summary: 'Ringkasan',
plate_no: 'No Plat',
registration_plate_no: 'No Plat Pendaftaran',
plate_no_label: 'Label No Plat',
tied_trailer: 'Trailer Berikat',
driver: 'Pemandu',
new_driver: 'Pemandu Baru',
group: 'Kumpulan',
device_id: 'ID Peranti',
sim_card_no: 'No Kad SIM',
card_no: 'No Kad',
registration_date: 'Tarikh Pendaftaran',
installation_date: 'Tarikh Pemasangan',
warranty_installation_date: 'Tarikh Waranti Pemasangan',
fuel_consumption: 'Penggunaan Bahan Api',
car_utilization: 'Penggunaan Kereta',
safety_level: 'Tahap Keselamatan',
group_name: 'Nama Kumpulan',
ignition: 'Penghidup Enjin',
first_drive: 'Pemanduan Pertama',
pto: 'PTO',
time: 'Masa',
idle_duration: 'Tempoh Tertidur',
speed: 'Kelajuan',
device_status: 'Status Peranti',
fuel: 'Bahan Api',
today_mileage: 'Perbatuan Hari Ini',
address: 'Alamat',
custom_view: 'Pandangan Tersuai',
set_stop_duration: 'Tetapkan Tempoh Berhenti',
full_map: 'Peta Penuh',
date_time: 'Tarikh Masa',
fuel_level: 'Tahap Bahan Api',
acc: 'ACC',
mileage_km: 'Perbatuan (KM)',
start_point: 'Titik Mula',
end_point: 'Titik Tamat',
starting_mileage: 'Perbatuan Permulaan',
location: 'Lokasi',
idling_duration: 'Tempoh Tertidur',
parking_duration: 'Tempoh Parkir',
nombor: 'No.',
daily_summary: 'Ringkasan Harian',
live_tracking: 'Penjejakan Langsung',
view_profile: 'Lihat Profil',
fuel_report: 'Laporan Bahan Api',
pto_log: 'Log PTO',
maintenance_log: 'Log Penyelenggaraan',
stop_recover_engine: 'Berhenti Pulihkan Enjin',
assign_unassign_driver: 'Tugaskan Nyah Tugas Pemandu',
assign_unassign_tng_card: 'Tugaskan Nyah Tugas Kad TNG',
create_sharable_view: 'Cipta Pandangan Berkongsi',
live_video: 'Video Langsung',
status: 'Status',
engine_type: 'Jenis Enjin',
vehicle_type: 'Jenis Kenderaan',
normal: 'Normal',
idling: 'Berteduh',
parking: 'Parkir',
contact: 'Hubungi',
phone_no: 'No Telefon',
vehicle: 'Kenderaan',
poi: 'POI',
traffic: 'Trafik',
geofence: 'Geofence',
search_by_plate_no: 'Cari mengikut No Plat',
search_by_poi: 'Cari mengikut Nama POI',
search_by_geofence: 'Cari mengikut Nama Geofence',
all: 'Semua',
moving: 'Bergerak',
disconnected: 'Terputus',
geofence_entry_ot: 'Geofence Masuk OT',
speeding: 'Memandu laju',
ICE: 'Enjin Pembakaran Dalaman (ICE)',
PHEV: 'Kenderaan Elektrik Hibrid Palam (PHEV)',
EV: 'Kenderaan Elektrik (EV)',
car: 'Kereta',
bus: 'Bas',
truck: 'Lori',
machinery: 'Jentera',
machinery_crane: 'Jentera Kren',
mixer: 'Lori Pengadun',
van: 'Van',
crane: 'Kren',
motor: 'Motosikal',
strong: 'Kuat',
medium: 'Sederhana',
weak: 'Lemah',
healthy: 'Sihat',
aware: 'Berjaga-jaga',
abnormal: 'Tidak Normal',
'open circuit': 'Litar Terbuka',
'short circuit': 'Litar Pendek',
unknown: 'Tidak Diketahui',
need_replacement: 'Perlu Ganti',
full_charge: 'Pengecasan Penuh',
charging: 'Sedang Mengecas',
low_charge: 'Pengecasan Rendah',
and_above: 'dan ke atas',
and_below: 'dan ke bawah',
engine_service_date: 'Tarikh Servis Enjin',
transmission_gear_box_service_date: 'Tarikh Servis Transmisi / Kotak Gear',
electrical_system_service_date: 'Tarikh Servis Sistem Elektrik',
please_select_vehicle: 'Sila pilih kenderaan terlebih dahulu...',
show_route: 'Tunjukkan Laluan',
hide_route: 'Sembunyikan Laluan',
live_data: 'Data Langsung',
maintenance: 'Penyelenggaraan',
add_poi: 'Tambah POI',
poi_details: 'Butiran POI',
poi_name: 'Nama POI',
poi_latitude: 'Latitud POI',
poi_longitude: 'Longitud POI',
poi_icon: 'Ikon POI',
poi_radius: 'Radius POI',
poi_contacter: 'Penyambung POI',
poi_phone: 'Telefon POI',
poi_address: 'Alamat POI',
poi_remark: 'Catatan POI',
total_cost: 'Jumlah Kos',
total_spend: 'Jumlah Perbelanjaan',
code: 'Kod',
description: 'Penerangan',
maintenance_category: 'Kategori Penyelenggaraan',
maintenance_form: 'Borang Penyelenggaraan',
form_no: 'No. Borang',
workshop: 'Bengkel',
contact_no: 'No. Hubungi',
service_type: 'Jenis Servis',
cost_of_repair_rm: 'Kos Pembaikan (RM)',
urgent: 'Acuan',
warranty: 'Jaminan',
servicing: 'Penyelenggaraan',
troubleshooting: 'Penyelesaian Masalah',
puspakom: 'Puspakom',
accident: 'Kecelakaan',
total_lost: 'Jumlah Kerugian',
engine_overhaul: 'Pembaikan Enjin',
tyre: 'Tayar',
maintenance_form_details: 'Butiran Borang Penyelenggaraan',
repair_maintenance_order: 'Perintah Penyelenggaraan Pembetulan',
vehicle_condition_remark: 'Catatan Keadaan Kenderaan',
please_enter: 'Sila masukkan...',
please_select: 'Sila pilih',
partnership_workshop: 'Bengkel Perkongsian',
drive_in_date_time: 'Tarikh / Masa Masuk',
drive_out_date_time: 'Tarikh / Masa Keluar',
technician: 'Teknikan',
drop_off_driver: 'Hantar Pemandu',
pickup_driver: 'Ambil Pemandu',
estimate_complete_date: 'Tarikh Anggaran Selesai',
item: 'Item',
good: 'Baik',
front_head_lamps: 'Lampu Hadapan',
rear_tails_lamps: 'Lampu Belakang',
adjust_brake_pm_trailer: 'Laraskan Brek PM / Treler',
top_up_engine_oil: 'Tambah Minyak Enjin',
top_up_aircond_gas: 'Tambah Gas Penyaman Udara',
rear_front_view_mirror: 'Cermin Pandang Belakang',
air_leaking: 'Kebocoran Udara',
windscreen: 'Cermin Depan',
both_side_windows: 'Tetingkap Sisi Kiri dan Kanan',
wiring_problem: 'Masalah Pendawaian',
gear_problem: 'Masalah Gear',
wiper_blade: 'Bilah Penyapu',
permit_date: 'Tarikh Permit',
roadtax_date: 'Tarikh Cukai Jalan',
sgp_roadtax_date: 'Tarikh Cukai Jalan SGP',
puspakom_date: 'Tarikh Puspakom',
insurance_date: 'Tarikh Insurans',
service_date: 'Tarikh Servis',
remaining_mileage: 'Jarak Perjalanan Tersisa',
service_log: 'Log Servis',
vehicle_log: 'Log Kenderaan',
view_all: 'Lihat Semua',
category: 'Kategori',
type: 'Jenis',
last_service_mileage: 'Jarak Servis Terakhir',
next_date: 'Tarikh Seterusnya',
affordable_mileage: 'Jarak Mampu',
spare_part_cost: 'Kos Alat Ganti',
labour_cost: 'Kos Buruh',
supplier: 'Pembekal',
service: 'Servis',
last_service_date: 'Tarikh Servis Terakhir',
remark: 'Catatan',
service_details: 'Butiran Servis',
mark_as_done: 'Tandakan Sebagai Selesai',
details: 'Butiran',
history: 'Sejarah',
categories: 'Kategori',
maintenance_type: 'Jenis Penyelenggaraan',
date: 'Tarikh',
spare_cost: 'Kos Alat Ganti',
payment_date: 'Tarikh Pembayaran',
driver_name: 'Nama Pemandu',
license_no: 'No. Lesen',
license_expiry_date: 'Tarikh Tamat Tempoh Lesen',
psv_expiry_date: 'Tarikh Tamat PSV',
permit_expiry_date: 'Tarikh Tamat Permit',
gdl_expiry_date: 'Tarikh Tamat GDL',
company: 'Syarikat',
created_by: 'Dicipta Oleh',
created_at: 'Dicipta Pada',
last_updated: 'Dikemas Kini Terakhir',
last_updated_at: 'Dikemas Kini Pada %{time}',
last_location: 'Lokasi Terakhir',
check_in_time: 'Masa Daftar Masuk',
check_out_list: 'Senarai Daftar Keluar',
driver_list: 'Senarai Pemandu',
all_driver_list: 'Senarai Semua Pemandu',
driver_type: 'Jenis Pemandu',
check_in_date_time: 'Tarikh / Masa Daftar Masuk',
check_out_date_time: 'Tarikh / Masa Daftar Keluar',
checklist_no: 'No. Senarai Semak',
today_checked_in: 'Hari Ini Daftar Masuk',
today_checked_out: 'Hari Ini Daftar Keluar',
checked_in: 'Telah Daftar Masuk',
checked_out: 'Telah Daftar Keluar',
last_7_days: '7 Hari Terakhir',
today_total_checklist: 'Jumlah Senarai Semak Hari Ini',
driver_without_checklist: 'Pemandu Tanpa Senarai Semak',
vehicle_without_checklist: 'Kenderaan Tanpa Senarai Semak',
failed_item_no: 'No. Item Gagal',
verify_by: 'Sahkan Oleh',
verify_at: 'Sahkan Pada',
verify: 'Sahkan',
driver_claim_overview: 'Gambaran Keseluruhan Tuntutan Pemandu',
last_30_days: '30 Hari Terakhir',
total_claim: 'Jumlah Tuntutan',
total_petrol_claim: 'Jumlah Tuntutan Petrol',
toll_claim: 'Tuntutan Tol',
total_toll_claim: 'Jumlah Tuntutan Tol',
total_number_of_driver: 'Jumlah Pemandu',
petrol_claim: 'Tuntutan Petrol',
petrol_toll_claim: 'Tuntutan Petrol dan Tol',
today: 'Hari Ini',
yesterday: 'Semalam',
last_week: 'Minggu Lepas',
last_month: 'Bulan Lepas',
weekly: 'Mingguan',
monthly: 'Bulanan',
yearly: 'Tahunan',
driver_claim_history: 'Sejarah Tuntutan Pemandu',
toll_claim_rm: 'Tuntutan Tol (RM)',
petrol_claim_rm: 'Tuntutan Petrol (RM)',
total_cost_rm: 'Jumlah Kos (RM)',
driver_claim_profile: 'Profil Tuntutan Pemandu',
claim_date: 'Tarikh Tuntutan',
reviewed_by: 'Disemak Oleh',
reviewed_date: 'Tarikh Disemak',
attachment: 'Lampiran',
attachments: 'Lampiran-lampiran',
review: 'Semak',
reject: 'Tolak',
approve: 'Luluskan',
point_to_point_distance: 'Jarak Titik ke Titik',
point: 'Titik',
add_point: 'Tambah Titik',
calculate: 'Kira',
duration: 'Tempoh',
pto_status: 'Status PTO',
on: 'Hidup',
off: 'Mati',
index: 'Indeks',
geofence_instance_name: 'Nama Instans Geofence',
number_of_geofence: 'Bilangan Geofence',
creator: 'Pencipta',
manage: 'Urus',
device_integration: 'Integrasi Peranti',
geofence_details: 'Butiran Geofence',
trailer_no: 'No. Treler',
roadtax_expiry_date: 'Tarikh Tamat Cukai Jalan',
sgp_roadtax_expiry_date: 'Tarikh Tamat Cukai Jalan SGP',
sgp_expiry_date: 'Tarikh Tamat SGP',
"puspakom_expiry_date": "Tarikh Tamat Puspakom",
    "insurance_expiry_date": "Tarikh Tamat Insurans",
    "pma_expiry_date": "Tarikh Tamat PMA",
    "trailer": "Treler",
    "trailer_profile": "Profil Treler",
    "trailer_details": "Butiran Treler",
    "roadtax_no": "No. Cukai Jalan",
    "singapore_roadtax_no": "No. Cukai Jalan Singapura",
    "singapore_roadtax_expiry_date": "Tarikh Tamat Cukai Jalan Singapura",
    "puspakom_no": "No. Puspakom",
    "insurance_no": "No. Insurans",
    "pma_no": "No. PMA",
    "insurance_sum_assured_rm": "Jumlah Insurans (RM)",
    "assigned_prime_mover": "Prime Mover Yang Ditetapkan",
    "rfid_no": "No. RFID",
    "total_active_vehicle": "Jumlah Kenderaan Aktif",
    "total_vehicle": "Jumlah Kenderaan",
    "suspicious_activity": "Aktiviti Mencurigakan",
    "maintenance_notification": "Pemberitahuan Penyelenggaraan",
    "no_of_alert": "Jumlah Amaran",
    "expenses": "Perbelanjaan",
    "fuel_usage_mileage": "Penggunaan Minyak & Perjalanan",
    "last_update_on": "Kemaskini Terakhir Pada %{date}",
    "environment_gas_emission": "Emisi Gas Alam Sekitar",
    "table_of_environment_gas_emission": "Jadual Emisi Gas Alam Sekitar",
    "environment_gas_emission_report": "Laporan Emisi Gas Alam Sekitar",
    "month": "Bulan",
    "co2_kg": "CO2 (kg)",
    "ch2_gms": "CH2 (gms)",
    "so2_gms": "SO2 (gms)",
    "no2_gms": "NOX (gms)",
    "pm_gms": "PM (gms)",
    "hc_gms": "HC (gms)",
    "compare_to_last_month": "berbanding dengan bulan lepas",
    "from_previous_month": "dari bulan sebelumnya",
    "total_mileage_km": "Jumlah Perjalanan (km)",
    "total_travel_time": "Jumlah Masa Perjalanan",
    "total_idling_duration": "Jumlah Durasi Menganggur",
    "fuel_economy": "Ekonomi Minyak",
    "chart_of_daily_utilization_of_vehicles": "Carta Penggunaan Harian Kenderaan",
    "summary_report_list": "Senarai Laporan Ringkasan",
    "vehicle_report_list": "Senarai Laporan Kenderaan",
    "driver_report_list": "Senarai Laporan Pemandu",
    "vehicle_active_report": "Laporan Kenderaan Aktif",
    "overall_fleet_fuel_avg_economy": "Purata Ekonomi Minyak Armada Keseluruhan",
    "ok": "Ok",
    "offline_for_1_2_days": "Offline selama 1-2 Hari",
    "offline_for_3_4_days": "Offline selama 3-4 Hari",
    "offline_for_5+_days": "Offline selama 5+ Hari",
    "not_active": "Tidak Aktif",
    "good_0.050km_0.1km_l": "Baik - 0.050km - 0.1km/l",
    "medium_0.050km_0.1km_l": "Sederhana - 0.050km - 0.1km/l",
    "alert_0.050km_0.1km_l": "Amaran - 0.050km - 0.1km/l",
    "top5_idling_time": "Top 5 Masa Menganggur",
    "top5_high_speed": "Top 5 Kelajuan Tinggi",
    "top5_high_fuel_consumption": "Top 5 Penggunaan Minyak Tinggi",
    "top5_fuel_economy": "Top 5 Ekonomi Minyak",
    "driver_score": "Skor Pemandu",
    "average_driver_score": "Skor Purata Pemandu",
    "driver_status": "Status Pemandu",
    "top5_safety_driver": "Top 5 Pemandu Selamat",
    "top5_high_risk_driver": "Top 5 Pemandu Berisiko Tinggi",
    "excellent": "Cemerlang",
    "fair": "Sederhana",
    "critical": "Kritikal",
    "high": "Tinggi",
    "distance_km": "Jarak (km)",
    "active_driver": "Pemandu Aktif",
    "event": "Peristiwa",
    "no_of_vehicles": "Jumlah Kenderaan",
    "utilized_vehicles": "Kenderaan Digunakan",
    "kilometer": "Kilometer",
    "liter": "Liter",
    "fuel_usage": "Penggunaan Minyak",
    "mileage": "Perjalanan",
    "trips": "Perjalanan",
    "vehicle_performance": "Prestasi Kenderaan",
    "driver_app": "Aplikasi Pemandu",
    "safety": "Keselamatan",
    "spend": "Perbelanjaan",
    "operator": "Pengendali",
    "alert": "Amaran",
    "diagnosis": "Diagnosis",
    "all_vehicle_summary_report": "Laporan Ringkasan Semua Kenderaan",
    "vehicle_status_log": "Log Status Kenderaan",
    "vehicle_usage_report": "Laporan Penggunaan Kenderaan",
    "trips_report": "Laporan Perjalanan",
    "geofence_report": "Laporan Geofence",
    "poi_report": "Laporan POI",
    "machinery_summary_report": "Laporan Ringkasan Mesin",
    "maintenance_log_report": "Laporan Log Penyelenggaraan",
    "idling_summary_report": "Laporan Ringkasan Menganggur",
    "raw_data_report": "Laporan Data Mentah",
    "engine_report": "Laporan Enjin",
    "temperature_chart": "Carta Suhu",
    "temperature": "Suhu",
    "pto_camera_report": "Laporan Kamera PTO",
    "rotation_report": "Laporan Putaran",
    "driving_record_report": "Laporan Rekod Pemanduan",
    "trailer_log_report": "Laporan Log Treler",
    "trailer_raw_report": "Laporan Mentah Treler",
    "driver_summary_report": "Laporan Ringkasan Pemandu",
    "driver_logs_report": "Laporan Log Pemandu",
    "driver_trips_report": "Laporan Perjalanan Pemandu",
    "driver_activity_report": "Laporan Aktiviti Pemandu",
    "driver_activity_summary_report": "Laporan Ringkasan Aktiviti Pemandu",
    "driver_score_report": "Laporan Skor Pemandu",
    "driver_safety_report": "Laporan Keselamatan Pemandu",
    "driver_safety_behaviour_report": "Laporan Perilaku Keselamatan Pemandu",
    "driver_job_report": "Laporan Pekerjaan Pemandu",
    "get_checklist_report": "Dapatkan Laporan Senarai Semak",
    "vehicle_safety_report": "Laporan Keselamatan Kenderaan",
    "fatigue_photo_video_report": "Laporan Foto Video Keletihan",
    "fatigue_monitor_board": "Papan Pemantauan Keletihan",
    "fatigue_driving_record": "Rekod Pemanduan Keletihan",
    "toll_report": "Laporan Tol",
    "fuel_drop_report": "Laporan Penurunan Minyak",
    "refuel_summary_report": "Laporan Ringkasan Pengisian Semula",
    "mileage_liter_by_statement": "Perjalanan Liter Mengikut Pernyataan",
    "operator_company_daily_report": "Laporan Harian Syarikat Pengendali",
    "operator_attendance_report": "Laporan Kehadiran Pengendali",
    "operator_driver_report": "Laporan Pemandu Pengendali",
    "suspicious_activity_report": "Laporan Aktiviti Mencurigakan",
    "critical_alert_report": "Laporan Amaran Kritikal",
    "alert_report": "Laporan Amaran",
    "maintenance_alert_report": "Laporan Amaran Penyelenggaraan",
    "vehicle_diagnosis_report": "Laporan Diagnosis Kenderaan",
    "speed_report": "Laporan Kelajuan",
    "overspeed_record_report": "Laporan Rekod Kelajuan Berlebihan",
    "report": "Laporan",
    "yes": "Ya",
    "no": "Tidak",
    "item_for_inspection": "Item Untuk Pemeriksaan",
    "minyak_power_setting": "Tetapan Kuasa Minyak",
    "recently_viewed": "Lihat Baru-Baru Ini",
    "starred": "Ditanda Bintang",
    "idling_time_min": "Masa Menganggur (Minit)",
    "report_type": "Jenis Laporan",
    "customize_column": "Sesuaikan Ruangan",
    "fleet": "Armada",
    "max_speed_km_h": "Kelajuan Maksimum (KM/J)",
    "fuel_usage_l": "Penggunaan Minyak (L)",
    "fuel_cost_rm": "Kos Minyak (RM)",
    "refuel_amount_l": "Jumlah Pengisian Semula (L)",
    "km_l": "KM/L",
    "fuel_efficiency_perc": "Kecekapan Minyak (%)",
    "fuel_consumption_km_l": "Penggunaan Minyak (KM/L)",
    "total_idle_duration": "Jumlah Durasi Menganggur",
    "unallowed_idle_duration": "Durasi Menganggur Tidak Dibenarkan",
    "idling_fuel_usage_l": "Penggunaan Minyak Menganggur (L)",
    "idle_cost_rm": "Kos Menganggur (RM)",
    "total_driving_duration": "Jumlah Durasi Pemanduan",
    "total_journey_time": "Jumlah Masa Perjalanan",
    "avg_daily_speed_km_h": "Purata Kelajuan Harian (KM/J)",
    "data_summary": "Ringkasan Data",
    "data_details": "Butiran Data",
    "profile_info": "Maklumat Profil",
    "training_record": "Rekod Latihan",
    "violation_record": "Rekod Pelanggaran",
    "driver_detail": "Butiran Pemandu",
    "driver_details": "Butiran Pemandu",
    "staff_id": "ID Staf",
    "tel_no_hp": "No. Tel (hp)",
    "date_of_birth": "Tarikh Lahir",
    "emergency_contact": "Kontak Kecemasan",
    "nric": "NRIC",
    "email": "Emel",
    "gender": "Jantina",
    "password": "Kata Laluan",
    "nationality": "Kewarganegaraan",
    "rental_fee": "Yuran Sewa",
    "other_information": "Maklumat Lain",
    "other_informations": "Maklumat Lain-Lain",
    "driving_license_no": "No. Lesen Memandu",
    "driving_license_expiry_date": "Tarikh Tamat Lesen Memandu",
    "commercial_license_no_gdl": "No. Lesen Komersial (GDL)",
    "comm_license_no_expiry_date": "Tarikh Tamat Lesen Komersial",
    "permit_no": "No. Permit",
    "psv_no": "No. PSV",
    "psv_license_expire": "Tarikh Tamat Lesen PSV",
    "hired_date": "Tarikh Diambil Bekerja",
    "salary": "Gaji",
    "bank_acc": "Akaun Bank",
    "bank_acc_no": "No. Akaun Bank",
    "assign_to": "Tugaskan Kepada",
    "employment_status": "Status Pekerjaan",
    "permanent": "Permanen",
    "note": "Nota",
    "ic_license_upload": "Muat Naik IC & Lesen",
    "ic": "IC",
    "driving_license": "Lesen Memandu",
    "comm_license_gdl": "Lesen Komersial (GDL)",
    "training_title": "Tajuk Latihan",
    "attended_date": "Tarikh Hadir",
    "expiry_date": "Tarikh Tamat",
    "cost": "Kos",
    "cost_rm": "Kos (RM)",
    "compulsory": "Wajib",
    "violation_type": "Jenis Pelanggaran",
    "violation_date": "Tarikh Pelanggaran",
    "fine": "Denda",
    "fine_rm": "Denda (RM)",
    "pay_by": "Bayar Mengikut",
    "other_details": "Butiran Lain",
    "driver_apps_credential": "Kredential Aplikasi Pemandu",
    "pick_your_birthday": "Pilih Tarikh Lahir Anda",
    "male": "Lelaki",
    "female": "Perempuan",
    "next": "Seterusnya",
    "malaysian": "Warganegara Malaysia",
    "non_malaysian": "Bukan Warganegara Malaysia",
    "direct_driver": "Pemandu Langsung",
    "feeder_driver": "Pemandu Feeder",
    "comm_license": "Lesen Komersial",
    "please_ignore_it_if_not_applicable": "* Sila Abaikan Jika Tidak Berkaitan",
    "username": "Nama Pengguna",
    "assign_to_supervisor": "Tugaskan Kepada Penyelia",
    "supervisor": "Penyelia",
    "route_group": "Kumpulan Laluan",
    "route_in_charge": "Laluan Di bawah Tanggungjawab",
    "trip_details": "Butiran Perjalanan",
    "historical_playback": "Main Balik Sejarah",
    "alert_log": "Log Amaran",
    "avg_speed": "Kelajuan Purata",
    "activities": "Aktiviti",
    "driver_distance": "Jarak Pemandu",
    "violation": "Pelanggaran",
    "device_type": "Jenis Peranti",
    "color": "Warna",
    "manufacturer_model": "Model Pengilang",
    "chasis_no": "No. Chasis",
    "owned_sub_con": "Milikan/Sub-Kontraktor",
    "capacity": "Kapasiti",
    "capacity_unit": "Unit Kapasiti",
    "tng_no": "No. TNG",
    "fleet_card_no": "No. Kad Armada",
    "tank_no": "No. Tangki",
    "electrical_system": "Sistem Elektrik",
    "vehicle_details": "Butiran Kenderaan",
    "vehicle_industry": "Industri Kenderaan",
    "speed_limit_km_h": "Had Kelajuan (km/j)",
    "allowing_idling_time": "Membenarkan Masa Menganggur",
    "operation_hour_per_week_hrs": "Jam Operasi Seminggu (jam)",
    "main_road_condition": "Keadaan Jalan Utama",
    "good_type": "Jenis Baik",
    "estimated_overload_perc": "Anggaran Lebihan Beban (%)",
    "idling_alert_setting": "Tetapan Amaran Menganggur",
    "idling_minutes": "Minit Menganggur",
    "please_leave_blank_for_using_the_default_idling_setting": "* Sila biarkan kosong untuk menggunakan tetapan menganggur lalai",
    "minutes": "Minit",
    "low_fuel_alert_setting": "Tetapan Amaran Minyak Rendah",
    "low_fuel_percentage": "Peratusan Minyak Rendah",
    "please_leave_blank_for_using_the_default_low_fuel_setting": "* Sila biarkan kosong untuk menggunakan tetapan minyak rendah lalai",
    "low_fuel_critical_alert": "Amaran Kritikal Minyak Rendah",
    "fuel_drop_percentage": "Peratusan Penurunan Minyak",
    "please_leave_blank_for_using_the_default_fuel_drop_setting": "* Sila biarkan kosong untuk menggunakan tetapan penurunan minyak lalai",
    "please_leave_blank_for_using_the_default_fuel_efficiency": "* Sila biarkan kosong untuk menggunakan kecekapan minyak lalai",
    "please_leave_blank_for_using_the_default_fuel_waste_rate": "* Sila biarkan kosong untuk menggunakan kadar pembaziran minyak lalai",
    "please_leave_blank_for_using_the_default_minimum_refuel_detection": "* Sila biarkan kosong untuk menggunakan pengesanan pengisian semula minimum lalai",
    "prohibit_driving_periods": "Tempoh Larangan Pemanduan",
    "add_new_prohibit_period": "Tambah Tempoh Larangan Baru",
    remove_all: 'Buang Semua',
    document_due_date_reminder: 'Peringatan Tarikh Akhir Dokumen',
    reminder_date: 'Tarikh Peringatan',
    vehicle_documents: 'Dokumen Kenderaan',
    registration_certificate: 'Sijil Pendaftaran',
    fuel_history: 'Sejarah Bahan Api',
    today_active_inactive_vehicle: 'Kenderaan Aktif & Tidak Aktif Hari Ini',
    distance: 'Jarak',
    maintenance_alert_reminder: 'Peringatan Amaran Penyelenggaraan',
    past_checklist: 'Senarai Semak Lalu',
    today_checklist: 'Senarai Semak Hari Ini',
    section: 'Bahagian',
    na: 'N/A',
    click_to_upload: 'Klik Untuk Muat Naik',
    vehicle_profile: 'Profil Kenderaan',
    vehicle_report: 'Laporan Kenderaan',
    chassis_no: 'Nombor Casis',
    fuel_rate: 'Kadar Bahan Api',
    toll_class: 'Kelas Tol',
    oil_volume: 'Isi Padu Minyak',
    tyre_management: 'Pengurusan Tayar',
    qr_code: 'Kod QR',
    certificate: 'Sijil',
    oil_measurement_setting: 'Tetapan Ukuran Minyak',
    total_fuel_usage: 'Jumlah Penggunaan Bahan Api',
    average_fuel_consumption: 'Purata Penggunaan Bahan Api',
    tyres_healthy_level: 'Tahap Kesihatan Tayar',
    vehicle_utilization: 'Penggunaan Kenderaan',
    vehicle_safety_level: 'Tahap Keselamatan Kenderaan',
    cost_per_km: 'Kos Per Km',
    cpk_value_rm: 'Nilai CPK (RM)',
    upcoming: 'Akan Datang',
    overdue: 'Tertunggak',
    group_average_fuel_performance: 'Purata Prestasi Bahan Api Kumpulan',
    ltr_fuel_waste_when_idling: '%{val} liter pembaziran bahan api semasa melahu',
    engine: 'Enjin',
    next_service_date: 'Tarikh Servis Seterusnya',
    remaining_km: 'Baki km',
    next_km: 'Km Seterusnya',
    last_km: 'Km Terkini',
    tyre_maintenance_log: 'Log Penyelenggaraan Tayar',
    updated_at: 'Dikemas Kini Pada',
    updated_by: 'Dikemas Kini Oleh',
    view_detail: 'Lihat Perincian',
    installed_date: 'Tarikh Pemasangan',
    tread_depth: 'Kedalaman Bunga',
    sn: 'N/S',
    tyre_pressure: 'Tekanan Tayar',
    brand: 'Jenama',
    used_km: 'Km Digunakan',
    model: 'Model',
    estimated_remaining_km: 'Jangkaan Km Baki',
    size: 'Saiz',
    cooling_system: 'Sistem Penyejukan',
    transmission_gear_box: 'Kotak Gear Transmisi',
    braking: 'Sistem Brek',
    body: 'Badan',
    other: 'Lain-lain',
    add_new_record: 'Tambah Rekod Baharu',
    vehicle_kpi: 'KPI Kenderaan',
    permit: 'Permit',
    roadtax: 'Cukai Jalan',
    sgp_roadtax: 'Cukai Jalan SGP',
    insurance: 'Insurans',
    expired_soon: 'Akan Tamat Tempoh',
    expired: 'Telah Tamat Tempoh',
    expired_date: 'Tarikh Tamat Tempoh',
    add_new_instance: 'Tambah Instance Baharu',
    geofence_list: 'Senarai Geofence',
    integration_type: 'Jenis Integrasi',
    add_geofence: 'Tambah Geofence',
    add_new_geofence: 'Tambah Geofence Baharu',
    edit_geofence: 'Sunting Geofence',
    zone_type: 'Jenis Zon',
    mode: 'Mod',
    overtime_minute: 'Minit Lebih Masa',
    check_point: 'Titik Semak',
    check_point_without_notification: 'Titik Semak Tanpa Pemberitahuan',
    prohibited: 'Dilarang',
    in_region: 'Dalam Kawasan',
    out_region: 'Luar Kawasan',
    in_out_region: 'Dalam/Luar Kawasan',
    events: 'Peristiwa',
    driving_distance: 'Jarak Pemanduan',
    remaining: 'Baki',
    days: 'Hari',
    day: 'Hari',
    documentation_log: 'Log Dokumentasi',
    documentation: 'Dokumentasi',
    machinery_tracking: 'Penjejakan Mesin',
    safety_equipment: 'Peralatan Keselamatan',
    update_documentation_log: 'Kemas Kini Log Dokumentasi',
    update_machinery_tracking: 'Kemas Kini Penjejakan Mesin',
    update_safety_equipment: 'Kemas Kini Peralatan Keselamatan',
    engine_on_date: 'Tarikh Enjin Dihidupkan',
    engine_on_remaining_duration_hours: 'Baki Tempoh Enjin Dihidupkan (Jam)',
    fire_extinguisher_expiry_date: 'Tarikh Luput Alat Pemadam Api',
    tanker_calibration_expiry_date: 'Tarikh Luput Kalibrasi Tangki',
    permit_no_expiry_date: 'No. Permit (Tarikh Luput)',
    roadtax_no_expiry_date: 'No. Cukai Jalan (Tarikh Luput)',
    singapore_roadtax_no_expiry_date: 'No. Cukai Jalan Singapura (Tarikh Luput)',
    puspakom_receipt_no_expiry_date: 'No. Resit Puspakom (Tarikh Luput)',
    insurance_sum_assured: 'Jumlah Dijamin Insurans',
    upload_by_time: 'Dimuat Naik oleh %{name} pada %{time}',
    fuel_price: 'Harga Bahan Api',
    default_price_rm: 'Harga Asas (RM)',
    default: 'Asas',
    default_rate: 'Kadar Asas',
    enter_rate: 'Masukkan Kadar',
    predefine: 'Pratentukan',
    please_enter_code: 'Sila Masukkan Kod',
    please_enter_description: 'Sila Masukkan Keterangan',
    please_enter_default_rate: 'Sila Masukkan Kadar Asas',
    please_enter_correct_rate: 'Sila Masukkan Kadar Betul',
    please_complete_all_rate_details: 'Sila Lengkapkan Semua Butiran Kadar',
    create_fuel_price: 'Cipta Harga Bahan Api',
    update_fuel_price: 'Kemas Kini Harga Bahan Api',
    delete_fuel_price: 'Padam Harga Bahan Api',
    success: 'Berjaya',
    error: 'Ralat',
    create_maintenance_category: 'Cipta Kategori Penyelenggaraan',
    update_maintenance_category: 'Kemas Kini Kategori Penyelenggaraan',
    delete_maintenance_category: 'Padam Kategori Penyelenggaraan',
    speed_limit: 'Had Laju',
    max_idling_duration: 'Tempoh Melahu Maksimum',
    operation_hour_per_week: 'Jam Operasi Seminggu',
    goods_type: 'Jenis Barang',
    estimated_overload: 'Anggaran Beban Berlebihan',
    km: 'km',
    km_h: 'km/j',
    mins: 'minit',
    hrs: 'jam',
    perc: '%',
    kg: 'kg',
    lbs: 'lbs',
    l_100km: 'ℓ/100km',
    hour_s: 'Jam',
    l_idling_min: 'ℓ/minit melahu',
    l: 'ℓ',
    please_enter_speed_limit: "Sila Masukkan Had Laju",
    please_enter_max_idling_duration: "Sila Masukkan Tempoh Melahu Maksimum",
    please_enter_operation_hour_per_week: "Sila Masukkan Jam Operasi Seminggu",
    please_enter_estimated_overload: "Sila Masukkan Anggaran Beban Berlebihan",
    please_select_road_condition: "Sila Pilih Keadaan Jalan",
    please_enter_goods_type: "Sila Masukkan Jenis Barang",
    general_road: "Jalan Biasa",
    highway: "Lebuhraya",
    fleet_group_name: "Nama Kumpulan Armada",
    fleet_group: "Kumpulan Armada",
    fleet_group_details: "Butiran Kumpulan Armada",
    add_emergency_contact: "Tambah Kenalan Kecemasan",
    emergency_contact_person: "Orang Kenalan Kecemasan",
    emergency_contact_number: "Nombor Kenalan Kecemasan",
    emergency_response_plan_setting: "Tetapan Pelan Tindak Balas Kecemasan",
    erp_contact_person: "Orang Kenalan ERP",
    erp_contact_no: "Nombor Kenalan ERP",
    please_enter_fleet_group_name: "Sila Masukkan Nama Kumpulan Armada",
    please_enter_contact_person_name: "Sila Masukkan Nama Orang Kenalan",
    please_enter_contact_number: "Sila Masukkan Nombor Kenalan",
    please_enter_correct_contact_number: "Sila Masukkan Nombor Kenalan yang Betul",
    please_complete_emergency_contact_details: "Sila Lengkapkan Butiran Kenalan Kecemasan",
    please_enter_correct_erp_contact_number: "Sila Masukkan Nombor Kenalan ERP yang Betul",
    basic_information: "Maklumat Asas",
    privacy_information: "Maklumat Privasi",
    advance_information: "Maklumat Lanjutan",
    geotab_integration: "Integrasi Geotab",
    geotab_server: "Pelayan Geotab",
    geotab_database: "Pangkalan Data Geotab",
    geotab_username: "Nama Pengguna Geotab",
    geotab_password: "Kata Laluan Geotab",
    apad_gateway_integration: "Integrasi Gerbang APAD",
    api_key: "Kunci API",
    api_password: "Kata Laluan API",
    sovy_driver_preinspection_check_integration: "Integrasi Pemeriksaan Awal Pemandu Sovy",
    sovy_username: "Nama Pengguna Sovy",
    sovy_password: "Kata Laluan Sovy",
    api_link: "Pautan API",
    company_id: "ID Syarikat",
    branch_id: "ID Cawangan",
    idle_setting: "Tetapan Melahu",
    vehicle_listing: "Senarai Kenderaan",
    enter_vehicle: "Masukkan Kenderaan",
    selected_vehicle: "Kenderaan Terpilih",
    no_vehicle: "Tiada Kenderaan",
    new_vehicle: "Kenderaan Baru",
    everyday: "Setiap Hari",
    weekday: "Hari Bekerja",
    weekend: "Hujung Minggu",
    monday: "Isnin",
    tuesday: "Selasa",
    wednesday: "Rabu",
    thursday: "Khamis",
    friday: "Jumaat",
    saturday: "Sabtu",
    sunday: "Ahad",
    update_service_log: "Kemas Kini Log Servis",
    create_service_log: "Cipta Log Servis",
    delete_maintenance: "Padam Penyelenggaraan",
    create_fleet_group: "Cipta Kumpulan Armada",
    update_fleet_group: "Kemas Kini Kumpulan Armada",
    delete_fleet_group: "Padam Kumpulan Armada",
    tracker_details: "Butiran Penjejak",
    tracker: "Penjejak",
    new_tracker: "Penjejak Baru",
    please_select_tracker: "Sila Pilih Penjejak",
    class_1_toll: 'KELAS 1 (Kenderaan dengan 2 gandar dan 3 atau 4 roda tidak termasuk teksi)',
    class_2_toll: 'KELAS 2 (Kenderaan dengan 2 gandar dan 5 atau 6 roda tidak termasuk bas)',
    class_3_toll: 'KELAS 3 (Kenderaan dengan 3 atau lebih gandar)',
    class_4_toll: 'KELAS 4 (Teksi)',
    class_5_toll: 'KELAS 5 (Bas)',
    class_1_penang_toll: 'KELAS 1 (Motosikal)',
    class_2_penang_toll: 'KELAS 2 (Kenderaan komersial dengan 3 roda dan kereta termasuk station wagon)',
    class_3_penang_toll: 'KELAS 3 (Lori dan van dengan 2 gandar dan 4 roda serta Bas dengan 2 gandar dan 4 roda)',
    class_4_penang_toll: 'KELAS 4 (Lori dan van dengan 2 gandar dan 5 atau 6 roda serta Bas dengan 2 gandar dan 5 atau 6 roda)',
    class_5_penang_toll: 'KELAS 5 (Kenderaan/Bas dengan 3 gandar)',
    class_6_penang_toll: 'KELAS 6 (Kenderaan dengan 4 gandar)',
    class_7_penang_toll: 'KELAS 7 (Kenderaan dengan 5 gandar atau lebih)',
    tyre_setting: 'Tetapan Tayar',
    tyre_maintenance: 'Penyelenggaraan Tayar',
    esg_setting: 'Tetapan ESG',
    esg_module: 'Modul ESG',
    fuel_brenchmark_setting: 'Tetapan Penanda Aras Bahan Api',
    fuel_efficiency: "Kecekapan Bahan Api",
    fuel_wastage_rate: "Kadar Pembaziran Bahan Api",
    toll_setting: "Tetapan Tol",
    penang_bridge_class: "Kelas Jambatan Pulau Pinang",
    mpob_integration: "Integrasi MPOB",
    mpob_device_id: "ID Peranti MPOB",
    cargomove_integration: 'Integrasi CargoMove',
    cargomove_plate_no: 'No Plat CargoMove',
    lpj_integration: 'Integrasi LPJ',
    lpj_plate_no: 'No Plat LPJ',
    upload: 'Muat Naik',
    reupload: 'Muat Naik Semula',
    are_you_sure_you_want_to_upload: 'Adakah anda pasti ingin memuat naik: ',
    tank_info: 'Maklumat Tangki',
    driver_reference: 'Rujukan Pemandu',
    region: 'Kawasan',
    transport_type: 'Jenis Pengangkutan',
    display_details: 'Paparkan Butiran',
    vehicle_icon: 'Ikon Kenderaan',
    marker_image: 'Imej Penanda',
    oil_setting: 'Tetapan Minyak',
    fuel_price_selection: 'Pemilihan Harga Bahan Api',
    engine_hour_setting: 'Tetapan Jam Enjin',
    engine_hour_meter: 'Meter Jam Enjin',
    system_calculated_engine_hour: 'Jam Enjin Dikira Sistem',
    adjust_to_engine_hour: 'Sesuaikan Ke Jam Enjin',
    last_engine_hour_adjustment: 'Pelarasan Jam Enjin Terakhir',
    please_note_that_any_adjustment_will_calculated_when_its_synced_engine_hour_is_available: "* Sila ambil perhatian bahawa sebarang pelarasan akan dikira apabila jam enjin disegerakkan tersedia",
    'set_adjust_to_engine_hour_to_-1_for_remove_the_adjustment': '* Tetapkan sesuaikan ke jam enjin kepada -1 untuk membuang pelarasan',
    pto_critical_alert: 'Amaran Kritikal PTO',
    pto_display: 'Paparan PTO',
    alert_triggered: 'Amaran Dijalankan',
    when_pto_is_on: 'Apabila PTO Diaktifkan',
    when_pto_is_off: 'Apabila PTO Dimatikan',
    never_trigger: 'Jangan Pernah Jalankan',
    upload_profile_image: 'Muat Naik Imej Profil',
    confirm_upload: 'Sahkan Muat Naik',
    billing_entity_name: 'Nama Entiti Pengebilan',
    vehicle_document: 'Dokumen Kenderaan',
    voltage: 'Voltan',
    create_vehicle: 'Cipta Kenderaan',
    update_vehicle: 'Kemas Kini Kenderaan',
    IDLING: 'Melahu',
    DRIVING: 'Memandu',
    PARKING: 'Parkir',
    OFFLINE: 'Luar Talian',
    battery: 'Bateri',
    satellite: 'Satelit',
    antenna: 'Antena',
    odometer: 'Odometer',
    fuel_details: 'Butiran Bahan Api',
    device_model: 'Model Peranti',
    terminal_id: 'ID Terminal',
    timezone: 'Zon Waktu',
    fuel_enable: 'Bahan Api Diaktifkan',
    device_mode: 'Mod Peranti',
    rotation_sensor_setting: 'Tetapan Sensor Putaran',
    rotation_sensor: 'Sensor Putaran',
    temperature_sensor_setting: 'Tetapan Sensor Suhu',
    temperature_sensor: 'Sensor Suhu',
    fuel_sensor_setting: 'Tetapan Sensor Bahan Api',
    fuel_sensor: 'Sensor Bahan Api',
    minimum_refuel_detection: 'Pengesanan Isi Semula Minimum',
    minimum_tank_lvl_oil_resistance: 'Tahap Tangki Minimum (%) / Rintangan Minyak (ohm)',
    maximum_tank_lvl_oil_resistance: 'Tahap Tangki Maksimum (%) / Rintangan Minyak (ohm)',
    fuel_tap_sensor: 'Sensor Injap Minyak',
    reverse_ohm: 'Terbalik (ohm)',
    sensor_type: 'Jenis Sensor',
    tank_volume: 'Isipadu Tangki',
    general: 'Umum',
    reverse: 'Terbalik',
    create_tracker: 'Cipta Penjejak',
    update_tracker: 'Kemaskini Penjejak',
    please_enter_sim_card_no: 'Sila masukkan nombor kad SIM',
    please_select_device_mode: 'Sila pilih mod peranti',
    please_enter_device_id: 'Sila masukkan ID peranti',
    please_select_device_type: 'Sila pilih jenis peranti',
    medical_expiry_date: 'Tarikh Luput Perubatan',
    create_driver: 'Cipta Pemandu',
    update_driver: 'Kemaskini Pemandu',
    resigned: 'Berhenti',
    suspended: 'Digantung',
    employed: 'Diambil Bekerja',
    please_enter_driver_name: 'Sila masukkan nama pemandu',
    please_select_employment_status: 'Sila pilih status pekerjaan',
    delete_driver: 'Padam Pemandu',
    vehicle_status: 'Status Kenderaan',
    update_vehicle_status: 'Kemaskini Status Kenderaan',
    generating_certificate: 'Menjana Sijil',
    please_wait_while_we_download_your_file_this_may_take_awhile_thanks: 'Sila tunggu semasa kami memuat turun fail anda, ini mungkin mengambil masa sebentar, terima kasih',
    map_instance: 'Instans Peta',
    owner: 'Pemilik',
    only_shown_account_enable: 'Hanya Akaun Ditunjukkan Diaktifkan',
    user: 'Pengguna',
    integration_list: 'Senarai Integrasi',
    online_status: 'Status Dalam Talian',
    movement_status: 'Status Pergerakan',
    please_enter_map_instance_name: 'Sila masukkan nama instans peta',
    please_select_integration_type: 'Sila pilih jenis integrasi',
    please_select_owner: 'Sila pilih pemilik',
    please_select_only_shown_account_enable: 'Sila pilih Hanya Akaun Ditunjukkan Diaktifkan',
    please_select_integration_list: 'Sila pilih senarai integrasi',
    profile: 'Profil',
    device_settings: 'Tetapan Peranti',
    user_settings: 'Tetapan Pengguna',
    manage_temporary_view: 'Urus Paparan Sementara',
    company_rules_complaints: 'Peraturan Syarikat & Aduan',
    help_center: 'Pusat Bantuan',
    edit_password: 'Edit Kata Laluan',
    company_logo: 'Logo Syarikat',
    current_password: 'Kata Laluan Semasa',
    new_password: 'Kata Laluan Baru',
    confirm_password: 'Sahkan Kata Laluan',
    please_enter_current_password: 'Sila masukkan kata laluan semasa',
    please_enter_new_password: 'Sila masukkan kata laluan baru',
    please_enter_confirm_password: 'Sila masukkan sahkan kata laluan',
    password_not_match: 'Kata Laluan Tidak Sepadan',
    faqs: 'Soalan Lazim',
    frequently_asked_questions: 'Soalan Lazim',
    keyword: 'Kata Kunci',
    focus_on: 'Tumpu Pada',
    driving_duration: 'Tempoh Pemanduan',
    delete_map_instance: 'Padam Instans Peta',
    focus_vehicle: 'Tumpu Kenderaan',
    driving: 'Memandu',
    fill_color: 'Isi Warna',
    stroke_color: 'Warna Garisan',
    delete_geofence: 'Padam Geofence',
    create_geofence: 'Cipta Geofence',
    update_geofence: 'Kemaskini Geofence',
    new_map_instance: 'Instans Peta Baru',
    create_poi: 'Cipta POI',
    update_poi: 'Kemaskini POI',
    delete_poi: 'Padam POI',
    meter: 'Meter',
    please_enter_poi_name: 'Sila masukkan nama POI',
    please_enter_poi_latitude: 'Sila masukkan latitud POI',
    please_enter_poi_longitude: 'Sila masukkan longitud POI',
    please_select_poi_icon: 'Sila pilih ikon POI',
    please_enter_poi_radius: 'Sila masukkan radius POI',
    firmware_management: 'Pengurusan Firmware',
    geofence_settings: 'Tetapan Geofence',
    alarm_configuration_vehicle_control: 'Konfigurasi Penggera / Kawalan Kenderaan',
    please_select_vehicle_to_set_alert_configurations_controls: 'Sila pilih kenderaan untuk menetapkan konfigurasi amaran / kawalan',
    enter_keyword_to_search: 'Masukkan kata kunci untuk mencari',
    device_settings_mode: 'Mod Tetapan Peranti',
    upload_firmware: 'Muat Naik Firmware',
    file_name: 'Nama Fail',
    firmware_upload: 'Muat Naik Firmware',
    click_here_to_upload_or_drop_your_files_here: 'Klik di sini untuk memuat naik atau seret fail anda ke sini',
    file: 'Fail',
    please_upload_firmware_file: 'Sila muat naik fail firmware',
    create_device_firmware: 'Cipta Firmware Peranti',
    update_device_firmware: 'Kemaskini Firmware Peranti',
    proceed: 'Teruskan',
    fleet_list: 'Senarai Armada',
    selected_fleet: 'Armada Dipilih',
    deselect: 'Nyahpilih',
    select: 'Pilih',
    vehicle_list: 'Senarai Kenderaan',
    hint: 'Petunjuk',
    device_firmware: 'Firmware Peranti',
    send: 'Hantar',
    please_select_device_firmware: 'Sila pilih firmware peranti',
    is_required: 'Diperlukan',
    wrong_format: 'Format Salah',
    must_be_a_number: 'Mesti nombor',
    immobilizer_password: 'Kata Laluan Immobilizer',
    please_enter_immobilizer_password: 'Sila masukkan kata laluan immobilizer',
    alarm_configuration_vehicle_control_history: 'Sejarah Konfigurasi Penggera / Kawalan Kenderaan',
    alarm_configuration_vehicle_control_records: 'Rekod Konfigurasi Penggera / Kawalan Kenderaan',
    cancelled: 'Dibatalkan',
    sent: 'Dihantar',
    settings_information: 'Maklumat Tetapan',
    settings: 'Tetapan',
    value: 'Nilai',
    return_raw: 'Pulangkan Data Mentah',
    return_value: 'Pulangkan Nilai',
    resend: 'Hantar Semula',
    refresh: 'Segarkan',
    cancel_all_command: 'Batalkan Semua Arahan',
    resend_all_command: 'Hantar Semula Semua Arahan',
    account_type: 'Jenis Akaun',
    new_user: 'Pengguna Baru',
    user_details: 'Butiran Pengguna',
    system_setting: 'Tetapan Sistem',
    email_reporting_extra_modules: 'Laporan E-mel / Modul Tambahan',
    fleet_group_listing: 'Senarai Kumpulan Armada',
    weekly_overspeed_mail_enable: 'E-mel Kelajuan Berlebihan Mingguan Dihidupkan',
    weekly_summary_mail_enable: 'E-mel Ringkasan Mingguan Dihidupkan',
    daily_summary_mail_enable: 'E-mel Ringkasan Harian Dihidupkan',
    daily_maintenance_mail_enable: 'E-mel Penyelenggaraan Harian Dihidupkan',
    admin: 'Pentadbir',
    main_account: 'Akaun Utama',
    sub_account: 'Sub Akaun',
    notification_email: 'E-mel Pemberitahuan',
    add_notification_email: 'Tambah E-mel Pemberitahuan',
    tracking_page_data_interval_in_second: 'Selang Data Laman Penjejakan (Dalam Saat)',
    suggested_value: 'Nilai Dicadangkan',
    currency_display: 'Paparan Mata Wang',
    initial_map_location: 'Lokasi Peta Awal',
    critical_alert_filter: 'Penapis Amaran Kritikal',
    select_fleet_group: 'Pilih Kumpulan Armada',
    no_fleet_group: 'Tiada Kumpulan Armada',
    selected_fleet_group: 'Kumpulan Armada Dipilih',
    new_fleet_group: 'Kumpulan Armada Baru',
    new_company: 'Syarikat Baru',
    company_name: 'Nama Syarikat',
    company_registration_no: 'No Pendaftaran Syarikat',
    company_contact_person: 'Orang Hubungan Syarikat',
    company_contact_no: 'No Hubungan Syarikat',
    company_details: 'Butiran Syarikat',
    company_address: 'Alamat Syarikat',
    user_listing: 'Senarai Pengguna',
    select_user: 'Pilih Pengguna',
    selected_user: 'Pengguna Dipilih',
    no_user: 'Tiada Pengguna',
    detail: 'Butiran',
    role: 'Peranan',
    new_role: 'Peranan Baru',
    roles_details: 'Butiran Peranan',
    role_name: 'Nama Peranan',
    features_enabled: 'Ciri-ciri Dihidupkan',
    modules: 'Modul',
    features: 'Ciri-ciri',
    get: 'Dapatkan',
    update: 'Kemaskini',
    create: 'Cipta',
    refuse_all: 'Tolak Semua',
    grant_all: 'Beri Semua',
    select_module_first: 'Pilih Modul Dahulu',
    new_setting: 'Tetapan Baru',
    setting_name: 'Nama Tetapan',
    using_users_fleet: 'Menggunakan Armada Pengguna',
    email_notification_setting_details: 'Butiran Tetapan Pemberitahuan E-mel',
    notify_email_entry: 'Pemberitahuan Kemasukan E-mel',
    geofence_instance_filter: 'Penapis Instans Geofence',
    notification_enabled: 'Pemberitahuan Dihidupkan',  
    nearby: 'Berdekatan',
    checklist_profile: 'Profil Senarai Semak',
    total_fuel_spend: 'Total Perbelanjaan Bahan Api',
    total_distance: 'Jarak Total',
    score: 'Skor',
    copy_vehicle_info: 'Salin informasi kenderaan ke papan klip berjaya',
    shortcuts: 'Pintasan',
    starred_your_favourites_to_quick_access: 'Menandai favorit anda untuk akses cepat',
    roadtax_attachment: 'Lampiran-lampiran Roadtax',
    permit_attachment: 'Lampiran-lampiran Permit',
    notifications: 'Notifikasi',
    view_all_notifications: 'Lihat Semua Notifikasi',
    critical_alert: 'Amaran Kritikal',
    notification: 'Notifikasi',
    driving_behaviour: 'Perilaku Kereta',
    grid_of: "Grid bagi %{column}",
    columns: 'Kolom',
    spotlight: 'Lampu Sorot',
    no_data_available: 'Tiada Data Tersedia',
    no_notification: 'Tiada Notifikasi',
    please_select_fleet_group_or_vehicle_first: 'Sila pilih kumpulan armada atau kenderaan pertama',
    enter_plate_no: 'Masukkan No Plat',
    select_type: 'Pilih Jenis',
    please_complete_all_required_fields: 'Sila Lengkapkan Semua Medan Diperlukan',
    please_enter_email: 'Sila Masukkan E-mel',
    please_select_account_type: 'Sila Pilih Jenis Akaun',
    please_select_status: 'Sila Pilih Status',
    template_name: 'Nama Templat',
    company_checklist: 'Senarai Semak Syarikat',
    checklist: 'Senarai Semak',
    please_select_checklist: 'Sila Pilih Senarai Semak',
    please_enter_company_name: 'Sila Masukkan Nama Syarikat',
    new_checklist: 'Senarai Semak Baru',
    select_a_workshop: 'Select a Workshop',
    rating_date: 'Rating Date',
    workshop_name: 'Workshop Name',
    checklist_details: 'Butiran Senarai Semak',
    select_a_company: 'Pilih Syarikat',
    checklist_name: 'Nama Senarai Semak',
    checklist_item_name: 'Nama Senarai Semak',
    enable_remark: 'Benarkan Nota',
    enable_attachment: 'Benarkan Lampiran',
    add_new_checklist_item: 'Tambah Senarai Semak Baru',
    checklist_items: 'Senarai Semak',
    select_a_status: 'Pilih Status',
    please_enter_city: 'Sila Masukkan Bandar',
    please_enter_contact_no: 'Sila Masukkan Nombor Hubungi',
    new_breakdown_support: 'Sokongan Perkembangan Baru',
    city: 'Bandar',
    contact_person: 'Orang Hubungi',
    latitude: 'Latitud',
    longitude: 'Longitude',
    pos_code: 'Pos Code',
    speciality: 'Jenis Istimewa',
    breakdown: 'Perkembangan',
    smart_fleet_analytics_dashboard: 'Papan Pemuka Analitik Armada Pintar',
    revenue_analysis_report: 'Laporan Analisis Hasil',
    total_expenses: 'Jumlah Perbelanjaan',
    total_earning: 'Jumlah Pendapatan',
    current_total_earning: 'Jumlah Pendapatan Semasa',
    cost_trend_report: 'Laporan Trend Kos',
    total_trip_cost: 'Jumlah Kos Perjalanan',
    total_maintenance_cost: 'Jumlah Kos Penyelenggaraan',
    average_monthly_cost: 'Kos Bulanan Purata',
    trip_cost: 'Kos Perjalanan',
    maintenance_cost: 'Kos Penyelenggaraan',
    trip_cost_breakdown: 'Laporan Kos Perjalanan',
    fuel_purchased: 'Minyak Dibeli',
    toll_cost: 'Kos Tol',
    driver_expense: 'Perbelanjaan Pemandu',
    add_report_to_starred_success: 'Tambahkan Laporan ke Starred berhasil',
    remove_report_from_starred_success: 'Laporan berjaya dikeluarkan daripada senarai kegemaran',
    remove_shortcut_success: 'Kembalikan pintasan berjaya',
    add_shortcut_success: 'Tambahkan pintasan berjaya',
    maintenance_cost_breakdown: 'Laporan Kos Penyelenggaraan',
    expense: 'Kelayakan',
    earning: 'Penghargaan', 
    top5_high_maintenance_cost: 'Top 5 Kos Penyelenggaraan Tinggi',
    fuel_performance_trend_report_for_industry_profile: 'Laporan Trend Performa untuk Profil Perusahaan',
    operation_performance: 'Performa Operasi',
    total_fuel_cost: 'Total Kos Minyak',
    est_idling_cost: 'Est Idling Cost', 
    industry_profile_efficiency_target: 'Kelayakan Keselarasan Profil Perusahaan',
    industry_profile_efficiency_baseline: 'Kelayakan Keselarasan Profil Perusahaan',
    fuel_consumption_auto_refuel: 'Minyak (Auto Refuel)',
    fuel_purchase_refuel: 'Pembelian Minyak (Refuel)',
    utilization_rate: 'Laporan Penggunaan',
    fuel_performance: 'Performa Minyak',
    revenue_rm: 'Penghargaan (RM)', 
    operational_performance_rm_km: 'Performa Operasi (RM/km)',
    average_per_vehicle: 'Rata-rata Per Kenderaan',
    this_month: 'Bulan Ini',
    target: 'Sasaran',
    total_cost_breakdown: 'Pecahan Jumlah Kos',
    top5_maintenance_breakdown: 'Pecahan 5 Penyelenggaraan Teratas',
    fuel_liter: 'Bahan Api (Liter)',
    total_vehicles: 'Jumlah Kenderaan',
    today_active: 'Aktif Hari Ini',
    today_inactive: 'Tidak Aktif Hari Ini',
    average_driver_score_speed: 'Skor Pemandu & Kelajuan',
    speed_km_h: 'Kelajuan (km/h)',
    average_idling_rate_per_total_driving_time: 'Rata-rata Penggunaan Melahu Per Masa Penyelenggaraan Jumlah',
    average_speeding_rate_per_total_driving_time: 'Rata-rata Penggunaan Penyelah Per Masa Penyelenggaraan Jumlah',
    average_harsh_event_per_total_driving_time: 'Rata-rata Perilaku Berbahaya Per Masa Penyelenggaraan Jumlah',
    top5_high_idling_event: 'Peristiwa Melahu Teratas',
    top5_high_speeding_event: 'Peristiwa Penyelah Teratas',
    top5_high_harsh_event: 'Peristiwa Berbahaya Teratas',
    fleet_idling_rate_trend: 'Trend Penggunaan Melahu Armada',
    fleet_speeding_rate_trend: 'Trend Penggunaan Penyelah Armada',
    fleet_harsh_event_trend: 'Trend Perilaku Berbahaya Armada',
    idling_of_total_drive_time_percentage: 'Penggunaan Melahu Jumlah Masa Penyelenggaraan (%)',
    speeding_of_total_drive_time_percentage: 'Penggunaan Penyelah Jumlah Masa Penyelenggaraan (%)',
    harsh_event_per_1000_km: 'Perilaku Berbahaya Per 1000 km',
    score_distribution: 'Distribusi Skor',
    poor: 'Sedikit',
    meet_goals: 'Mencapai Tujuan',
    top_performance: 'Performa Tertinggi',
    risk_factors_speeding: 'Risiko - Penyelah',
    tag: 'TAG',
    count: 'JUMLAH',
    rate_or_percentage: 'RATE atau %',
    score_impact: 'IMPAKAN SKOR',
    driver_count: 'Jumlah Pemandu',
}