import en from '@/local/languages/en.js'
import bm from '@/local/languages/bm.js'
import { createI18n } from 'vue-i18n'

const i18n = new createI18n({
    locale: localStorage.getItem("i18n") || 'en',
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false,
    messages: {
        en: en,
        ms: bm
    }
})

export default i18n;
